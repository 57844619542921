import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import store from "@/store/store";
//解决点击相同菜单报NavigationDuplicated错误
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "loginPage",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "",
    name: "FrontAdmin",
    component: () =>
      import(/* webpackChunkName: "FrontAdmin" */ "../views/FrontAdmin"),
    children: [
      // 首页
      {
        path: "",
        name: "HomePage",
        component: () =>
          import(/* webpackChunkName: "HomePage" */ "../views/HomePage.vue"),
      },
      // 平台介绍
      {
        path: "/introduce",
        name: "Introduce",
        component: () =>
          import(/* webpackChunkName: "Introduce" */ "../views/Introduce.vue"),
      },
      // 新闻详情
      {
        path: "/news-detail/:type/:newsId",
        name: "NewsDetail",
        component: () =>
          import(
            /* webpackChunkName: "NewsDetail" */ "../views/news/NewsDetail.vue"
          ),
      },
    ],
  },
  {
    path: "/adm",
    name: "Admin",
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "Admin" */ "../views/Admin.vue"),
    children: [
      {
        path: "/set-base",
        name: "SetBase",
        meta: {
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "SetBase" */ "../views/baseManagement/setBase"
          ),
      },
      {
        path: "/add-set-base/:type/:id",
        name: "AddSetBase",
        component: () =>
          import(
            /* webpackChunkName: "AddSetBase" */ "../views/baseManagement/addBase"
          ),
      },
      //优秀作品
      {
        path: "/excellent-works",
        name: "ExcellentWorks",
        component: () =>
          import(
            /* webpackChunkName: "ExcellentWorks" */ "../views/ExcellentWorks/ExcellentWorks"
          ),
      },
      // 基地信息维护
      {
        path: "/base-info-manage",
        name: "BaseInfoManage",
        component: () =>
          import(
            /* webpackChunkName: "BaseInfoManage" */ "../views/baseManagement/BaseInfoManage"
          ),
      },
      // 基地信息详情
      {
        path: "/base-info-detail/:type/:baseId",
        name: "BaseInfoDetail",
        component: () =>
          import(
            /* webpackChunkName: "BaseInfoDetail" */ "../views/baseManagement/EditBaseInfo"
          ),
      },
      // 基地信息详情
      {
        path: "/base-info-modify/:type/:baseId",
        name: "BaseInfoModify",
        component: () =>
          import(
            /* webpackChunkName: "BaseInfoModify" */ "../views/baseManagement/EditBaseInfo"
          ),
      },
      // 发布新闻
      {
        path: "/news",
        name: "News",
        component: () =>
          import(/* webpackChunkName: "News" */ "../views/news/News"),
      },
      // 新增新闻发布
      {
        path: "/add-news/:type",
        name: "AddNews",
        component: () =>
          import(/* webpackChunkName: "AddNews" */ "../views/news/AddNews"),
      },
      // 修改新闻
      {
        path: "/modify-news/:type/:informationId",
        name: "ModifyNews",
        component: () =>
          import(/* webpackChunkName: "ModifyNews" */ "../views/news/AddNews"),
      },
      // 基地管理员账号
      {
        path: "/base-admin",
        name: "BaseAdmin",
        component: () =>
          import(
            /* webpackChunkName: "BaseAdmin" */ "../views/baseAdminManagement/BaseAdmin"
          ),
      },
      // 指导老师管理
      {
        path: "/teacher-guide",
        name: "TeacherGuideManage",
        meta: {
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "TeacherManage" */ "../views/teacher/TeacherGuide"
          ),
      },
      // 新增指导老师
      {
        path: "/add-teacher-guide/:type",
        name: "AddTeacherGuide",
        component: () =>
          import(
            /* webpackChunkName: "AddTeacher" */ "../views/teacher/EditTeacherGuide"
          ),
      },
      // 修改指导老师
      {
        path: "/modify-teacher/:type/:userId",
        name: "ModifyTeacherGuide",
        component: () =>
          import(
            /* webpackChunkName: "ModifyTeacher" */ "../views/teacher/EditTeacherGuide"
          ),
      },
      // 带队老师管理
      {
        path: "/teacher-manage",
        name: "TeacherManage",
        component: () =>
          import(
            /* webpackChunkName: "TeacherManage" */ "../views/teacher/TeacherManage"
          ),
      },
      // 新增带队老师
      {
        path: "/add-teacher/:type",
        name: "AddTeacher",
        component: () =>
          import(
            /* webpackChunkName: "AddTeacher" */ "../views/teacher/EditTeacher"
          ),
      },
      // 修改带队老师
      {
        path: "/modify-teacher/:type/:userId",
        name: "ModifyTeacher",
        component: () =>
          import(
            /* webpackChunkName: "ModifyTeacher" */ "../views/teacher/EditTeacher"
          ),
      },
      // 产品管理
      {
        path: "/product-list",
        name: "ProductList",
        component: () =>
          import(
            /* webpackChunkName: "VillagerManage" */ "../views/product/ProductList"
            ),
      },
      {
        path: "edit-product/:id",
        name: "EditProduct",
        component: () =>
          import(/* webpackChunkName: "sys" */ "../views/product/EditProduct")
      },
      // 村民能者管理
      {
        path: "/villager-manage",
        name: "VillagerManage",
        component: () =>
          import(
            /* webpackChunkName: "VillagerManage" */ "../views/villager/VillagerManage"
          ),
      },
      // 新增村民能者
      {
        path: "/add-villager/:type",
        name: "AddVillager",
        component: () =>
          import(
            /* webpackChunkName: "AddVillager" */ "../views/villager/EditVillager"
          ),
      },
      // 修改村民能者
      {
        path: "/modify-villager/:type/:userId",
        name: "ModifyVillager",
        component: () =>
          import(
            /* webpackChunkName: "ModifyVillager" */ "../views/villager/EditVillager"
          ),
      },
      {
        path: "/course-sort-manage",
        name: "CourseSortManage",
        component: () =>
          import(
            /* webpackChunkName: "CourseSortManage" */ "../views/researchContent/CourseSortManage"
          ),
      },
      // 研学内容
      {
        path: "/research-content-manage",
        name: "ResearchContentManage",
        component: () =>
          import(
            /* webpackChunkName: "ResearchContentManage" */ "../views/researchContent/ResearchContentManage"
          ),
      },
      // 具体课程
      {
        path: "/course-manage",
        name: "CourseManage",
        component: () =>
          import(
            /* webpackChunkName: "CourseManage" */ "../views/researchContent/CourseManage"
          ),
      },
      // 研学场所
      {
        path: "/research-place-manage",
        name: "ResearchPlaceManage",
        component: () =>
          import(
            /* webpackChunkName: "ResearchPlaceManage" */ "../views/researchPlace/ResearchPlaceManage"
          ),
      },
      // 用户管理
      {
        path: "/user-manage",
        name: "UserManage",
        component: () =>
          import(
            /* webpackChunkName: "UserManage" */ "../views/system/UserManage"
          ),
      },
      // 新增用户
      {
        path: "/add-user/:type",
        name: "AddUser",
        component: () =>
          import(/* webpackChunkName: "AddUser" */ "../views/system/EditUser"),
      },
      // 修改用户
      {
        path: "/modify-user/:type/:userId",
        name: "ModifyUser",
        component: () =>
          import(
            /* webpackChunkName: "ModifyUser" */ "../views/system/EditUser"
          ),
      },
      // 角色管理
      {
        path: "/role-manage",
        name: "RoleManage",
        component: () =>
          import(
            /* webpackChunkName: "RoleManage" */ "../views/system/RoleManage"
          ),
      },
      // web权限管理
      {
        path: "/web-permission/:sysRoleId",
        name: "WebPermission",
        component: () =>
          import(
            /* webpackChunkName: "WebPermission" */ "../views/system/WebPermission"
          ),
      },
      // 研学计划管理
      {
        path: "/plan-manage",
        name: "PlanManage",
        component: () =>
          import(
            /* webpackChunkName: "PlanManage" */ "../views/plan/PlanManage"
          ),
      },
      // 行程信息列表
      {
        path: "/trip-information/:planId/:planName",
        name: "TripInformation",
        component: () =>
          import(
            /* webpackChunkName: "TripInformation" */ "../views/plan/TripInformation"
          ),
      },
      // 每日行程
      {
        path: "/daily-itinerary/:index/:planScheduleId",
        name: "DailyItinerary",
        component: () =>
          import(
            /* webpackChunkName: "DailyItinerary" */ "../views/plan/DailyItinerary"
          ),
      },
      // 研学活动管理
      {
        path: "/activity-manage",
        name: "ActivityManage",
        component: () =>
          import(
            /* webpackChunkName: "ActivityManage" */ "../views/activity/ActivityManage"
          ),
      },
      // 新增研学活动
      {
        path: "/add-activity/:type",
        name: "AddActivity",
        component: () =>
          import(
            /* webpackChunkName: "AddActivity" */ "../views/activity/EditActivity"
          ),
      },
      // 研学活动管理
      {
        path: "/modify-activity/:type/:activityId/:status",
        name: "ModifyActivity",
        component: () =>
          import(
            /* webpackChunkName: "ModifyActivity" */ "../views/activity/EditActivity"
          ),
      },
      // 研学活动详情
      {
        path: "/activity-detail/:type/:activityId",
        name: "ActivityDetail",
        component: () =>
          import(
            /* webpackChunkName: "ActivityDetail" */ "../views/activity/EditActivity"
          ),
      },
      // 研学活动报名情况
      {
        path: "/activity-registration",
        name: "ActivityRegistration",
        component: () =>
          import(
            /* webpackChunkName: "ActivityRegistration" */ "../views/activity/ActivityRegistration"
          ),
      },
      // 活动报名列表
      {
        path: "/activity-enroll-list/:activityId",
        name: "ActivityEnrollList",
        component: () =>
          import(
            /* webpackChunkName: "ActivityEnrollList" */ "../views/activity/ActivityEnrollList"
          ),
      },
      // 活动退款
      {
        path: "/activity-refund/:activityId",
        name: "ActivityRefund",
        component: () =>
          import(
            /* webpackChunkName: "ActivityRefund" */ "../views/activity/ActivityRefund"
          ),
      },
      // 开展详情
      {
        path: "/activity-development",
        name: "ActivityDevelopment",
        component: () =>
          import(
            /* webpackChunkName: "ActivityDevelopment" */ "../views/activity/ActivityDevelopment"
          ),
      },
      // 新增开展详情
      {
        path: "/activity-development-add/:type",
        name: "ActivityDevelopmentAdd",
        component: () =>
          import(
            /* webpackChunkName: "ActivityDevelopmentAdd" */ "../views/activity/EditActivityDevelopment"
          ),
      },
      // 修改开展详情
      {
        path: "/activity-development-modify/:type/:activityDevelopmentId",
        name: "ActivityDevelopmentModify",
        component: () =>
          import(
            /* webpackChunkName: "ActivityDevelopmentModify" */ "../views/activity/EditActivityDevelopment"
          ),
      },
      // 带队老师工资结算
      {
        path: "/teacher-salary-settlement",
        name: "TeacherSalarySettlement",
        component: () =>
          import(
            /* webpackChunkName: "TeacherSalarySettlement" */ "../views/salarySettlement/TeacherSalarySettlement"
          ),
      },
      //基地活动情况统计
      {
        path: "/base-wages",
        name: "BaseWages",
        meta: {
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "BaseWages" */ "../views/statisticalAnalysis/baseWages/baseWages"
          ),
      },
      //基地具体活动情况
      {
        path: "/base-specific-wages",
        name: "BaseSpecificWages",
        meta: {
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "BaseWages" */ "../views/statisticalAnalysis/baseWages/baseSpecificWages"
          ),
      },
      //平台收入统计
      {
        path: "/platform-charge-wages",
        name: "PlatformChargeWages",
        meta: {
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "PlatformChargeWages" */ "../views/statisticalAnalysis/platformWages/platformChargeWages"
          ),
      },
      //活动收费情况
      {
        path: "/platform-activity-charge-wages",
        name: "PlatformActivityChargeWages",
        meta: {
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "PlatformActivityChargeWages" */ "../views/statisticalAnalysis/platformWages/platformActivityChargeWages"
          ),
      },
      //活动工资支出
      {
        path: "/platform-wages",
        name: "PlatformWages",
        meta: {
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "PlatformWages" */ "../views/statisticalAnalysis/platformWages/platformWages"
          ),
      },
      //活动工资明细
      {
        path: "/platform-specific-wages",
        name: "PlatformSpecificWages",
        component: () =>
          import(
            /* webpackChunkName: "PlatformWages" */ "../views/statisticalAnalysis/platformWages/platformSpecificWages"
          ),
      },
      // 村民能者工资结算
      {
        path: "/villager-salary-settlement",
        name: "VillagerSalarySettlement",
        component: () =>
          import(
            /* webpackChunkName: "VillagerSalarySettlement" */ "../views/salarySettlement/VillagerSalarySettlement"
          ),
      },
      // 村民能者工资统计
      {
        path: "/villager-wages",
        name: "VillagerWages",
        meta: {
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "VillagerWages" */ "../views/statisticalAnalysis/villagerWages/VillagerWages"
          ),
      },
      // 村民能者-具体活动工资
      {
        path: "/villager-specific-wages/:baseId",
        name: "VillagerSpecificWages",
        meta: {
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "VillagerSpecificWages" */ "../views/statisticalAnalysis/villagerWages/VillagerSpecificWages"
          ),
      },
      // 村民能者个人工资结算列表
      {
        path: "/villager-salary-list/:baseId/:realName/:activityName",
        name: "VillagerSalaryList",
        component: () =>
          import(
            /* webpackChunkName: "VillagerSalaryList" */ "../views/statisticalAnalysis/villagerWages/VillagerSalaryList"
          ),
      },
      // 村民能者工资结算列表
      {
        path: "/villager-salary-list/:baseId",
        name: "VillagerSalaryLists",
        component: () =>
          import(
            /* webpackChunkName: "VillagerSalaryList" */ "../views/statisticalAnalysis/villagerWages/VillagerSalaryList"
          ),
      },
      // 村民能者工资结算列表
      {
        path: "/villager-salary-list",
        name: "VillagerSalaryListss",
        component: () =>
          import(
            /* webpackChunkName: "VillagerSalaryList" */ "../views/statisticalAnalysis/villagerWages/VillagerSalaryList"
          ),
      },
      // 村民能者个人活动工资
      {
        path: "/villager-person-wages/:baseId",
        name: "VillagerPersonWages",
        meta: {
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "VillagerPersonWages" */ "../views/statisticalAnalysis/villagerWages/VillagerPersonWages"
          ),
      },
      // 带队老师工资统计
      {
        path: "/teacher-wages",
        name: "TeacherWages",
        meta: {
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "TeacherWages" */ "../views/statisticalAnalysis/teacherWages/TeacherWages"
          ),
      },
      // 带队老师-具体活动工资
      {
        path: "/teacher-specific-wages/:baseId",
        name: "TeacherSpecificWages",
        component: () =>
          import(
            /* webpackChunkName: "TeacherSpecificWages" */ "../views/statisticalAnalysis/teacherWages/TeacherSpecificWages"
          ),
      },
      // 带队老师工资结算列表
      {
        path: "/teacher-salary-list/:baseId/:realName",
        name: "TeacherSalaryList",
        component: () =>
          import(
            /* webpackChunkName: "TeacherSalaryList" */ "../views/statisticalAnalysis/teacherWages/TeacherSalaryList"
          ),
      },
      // 带队老师全部工资结算列表
      {
        path: "/teacher-salary-list/:baseId",
        name: "TeacherSalaryListss",
        component: () =>
          import(
            /* webpackChunkName: "TeacherSalaryListss" */ "../views/statisticalAnalysis/teacherWages/TeacherSalaryList"
          ),
      },
      // 带队老师个人工资结算列表
      {
        path: "/teacher-salary-list/:baseId/:activityName",
        name: "TeacherSalaryLists",
        component: () =>
          import(
            /* webpackChunkName: "TeacherSalaryLists" */ "../views/statisticalAnalysis/teacherWages/TeacherSalaryList"
          ),
      },
      // 带队老师个人活动工资
      {
        path: "/teacher-person-wages/:baseId",
        name: "TeacherPersonWages",
        meta: {
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "TeacherPersonWages" */ "../views/statisticalAnalysis/teacherWages/TeacherPersonWages"
          ),
      },
      // 评价审核
      {
        path: "/evaluation-review",
        name: "EvaluationReview",
        component: () =>
          import(
            /* webpackChunkName: "EvaluationReview" */ "../views/activity/EvaluationReview"
          ),
      },
      // 评价详情
      {
        path: "/appraises-detail/:activityAppraiseId",
        name: "AppraisesDetail",
        component: () =>
          import(
            /* webpackChunkName: "AppraisesDetail" */ "../views/activity/AppraisesDetail"
          ),
      },
      // 退款管理
      {
        path: "/refund-manage",
        name: "RefundManage",
        component: () =>
          import(
            /* webpackChunkName: "RefundManage" */ "../views/activity/RefundManage"
          ),
      },
      // 退款详情
      {
        path: "/refund-detail/:activityOrderId/:type",
        name: "RefundDetail",
        component: () =>
          import(
            /* webpackChunkName: "RefundDetail" */ "../views/activity/RefundDetail"
          ),
      },
      // 退款受理
      {
        path: "/refund-acceptance/:activityOrderId/:type",
        name: "RefundAcceptance",
        component: () =>
          import(
            /* webpackChunkName: "RefundAcceptance" */ "../views/activity/RefundDetail"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop = 0;
  NProgress.start();
  const loggedIn = localStorage.getItem("userId");
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    NProgress.done();
    next({ name: "Login" });
  }
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
